<nav class="navbar navbar-light bg-white  navbar-expand-md ">
	<div class="container">
		<div class="col-2 pl-md-0 text-left"> <a [routerLink]="['/']" routerLinkActive="active" > <img src="../assets/images/logo-dlm.svg" height="60" alt="image"> </a> </div>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse-1" aria-controls="navbarNav6" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button>
		<div class="collapse navbar-collapse justify-content-center col-md-8 navbar-collapse-1">
			<ul class="navbar-nav justify-content-center">
				<li class="nav-item "> <a class="nav-link" [routerLink]="['/']">Company <span class="sr-only">(current)</span></a> </li>
				<li class="nav-item"> <a class="nav-link" [routerLink]="['contact']" >Contact</a> </li>
        <li class="nav-item"> <a class="nav-link" [routerLink]="['job']" >Job</a> </li>
			</ul>
		</div>
		<div class="collapse navbar-collapse justify-content-end col-md-2 navbar-collapse-1 pr-md-0">
			<ul class="navbar-nav">
				<li class="nav-item"> <a class="btn btn-dark bg-green ml-md-3 px-5" href="tel:(872)255-0007">Call</a> </li>
			</ul>
		</div>
	</div>
</nav>

<router-outlet></router-outlet>

<footer class="pt-4 pb-4  bg-dark text-white">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-12 col-md-8">
				<ul class="nav justify-content-center justify-content-md-start">
					<li class="nav-item"> <a class="nav-link active" [routerLink]="['/']" >Company</a> </li>
					   <li class="nav-item"> <a class="nav-link" [routerLink]="['terms-and-conditions']" >Terms and conditions</a> </li>
          <li class="nav-item"> <a class="nav-link" [routerLink]="['privacy-policy']" >Privacy policy</a> </li>
          <li class="nav-item"> <a class="nav-link" [routerLink]="['job']" >Job</a> </li>
          <li class="nav-item"> <a class="nav-link" [routerLink]="['contact']" >Contact</a> </li>
				</ul>
			</div>
			<div class="col-12 col-md-4 mt-4 mt-md-0 text-center text-md-right"> © 2021 DLM Logistics Inc All rights reserved</div>
		</div>
	</div>
</footer>
